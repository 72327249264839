import styled from '@emotion/styled';
import { memo, useCallback } from 'react';
import { RiAddLine } from 'react-icons/ri';
import { useSnapshot } from 'valtio';
import { fill_horizontal_all_center, fill_vertical_all_center, } from '~/modules/AppLayout/FlexGridCss';
import { signalrStore2 } from '~/modules/SDK/Signalr/signalrStore2';
import { useSymbolDictionaryStore } from '~/modules/SDK/Symbol/useSymbolDictionaryStore';
import { processQuoteToReadable } from '~/utils/processQuoteToReadable';
import { store } from '~/pages/heineken_template/_private/store';
import { Kbar } from '../Kbar';
import { useSortSignalrDataStore, useSortSignalrDataStore2, } from '~/modules/symbolQuote/simple/useSortStore';
import { SortArrow } from '../content/SortArrow';
import { VscClose } from 'react-icons/vsc';
//股票升降跳動要顯示的小數位
export const stockDecimal = (value) => {
    if (value < 100) {
        return 2;
    }
    else if (value >= 100 && value < 1000) {
        return 1;
    }
    else if (value >= 1000) {
        return 0;
    }
    else
        return 2;
};
const SymbolBody = memo(function SymbolBody(props) {
    const { state, acts } = props.symbolWatchList;
    const quote = props.quote;
    const dictionary = useSymbolDictionaryStore(s => s.dictionary);
    const value = useSnapshot(signalrStore2.values.quote)[quote.symbol];
    const quoteChanges = processQuoteToReadable(value);
    const quoteChangesPercent = quoteChanges.closeChangePercent;
    const changeArrow = quoteChangesPercent > 0 ? '+' : '';
    const close = value?.close ?? 0;
    const volume = value?.volume ?? 0;
    const amount = (close * 1000 * volume) / 1e8;
    const limitPrice = close === value?.limitDownPrice || close === value?.limitUpPrice;
    const hadSymbol = state.symbolListArray.includes(quote.symbol);
    const charting = useSnapshot(store).charting;
    const selected = charting.symbol === quote.symbol;
    return (<styleds.body.container onClick={() => store.charting.changeSymbol(quote.symbol)} selected={selected}>
      <styleds.body.addButton onClick={event => {
            event.stopPropagation();
            !hadSymbol ? acts.addSymbol(quote.symbol, 30) : acts.removeSymbol(quote.symbol);
        }}>
        {!hadSymbol && <RiAddLine color='#cacaca'/>}
        {hadSymbol && <VscClose color='#e95050'/>}
      </styleds.body.addButton>

      <styleds.body.content>
        <Kbar quote={value}/>
      </styleds.body.content>

      <styleds.body.symbolNameContent>
        <span>{dictionary[quote.symbol]}</span>
        <span>{quote.symbol}</span>
      </styleds.body.symbolNameContent>

      <styleds.body.quote quote={quoteChangesPercent}>
        <styleds.body.price limit={limitPrice} quote={quoteChangesPercent}>
          {close.toFixed(stockDecimal(close))}
        </styleds.body.price>
      </styleds.body.quote>

      <styleds.body.quote quote={quoteChangesPercent}>
        {changeArrow}
        {quoteChangesPercent.toFixed(2)}
      </styleds.body.quote>

      <styleds.body.content>{volume}</styleds.body.content>
      <styleds.body.content>
        <span>{amount.toFixed(1)}億</span>
      </styleds.body.content>
    </styleds.body.container>);
});
const SymbolHeader = memo(function SymbolHeader(props) {
    const sortGroup = props.sortGroup ?? 1;
    const displayKbarSort = props.displayKbarSort ?? true;
    /** 排序元件的組別 */
    const sortStore = () => {
        if (sortGroup === 2) {
            return useSortSignalrDataStore2;
        }
        else
            return useSortSignalrDataStore;
    };
    const sortState = useSnapshot(sortStore());
    const toggleOrderKey = sortState.orderKey === 'desc' ? 'asc' : 'desc';
    const sort = sortState.sortKey;
    const handleColumnSelected = useCallback((sortKey) => {
        sortStore().sortKey = sortKey;
        sortStore().orderKey = toggleOrderKey;
    }, [toggleOrderKey]);
    return (<styleds.header.container>
        <styleds.header.item selectd={false}/>
        <styleds.header.item onClick={() => handleColumnSelected(displayKbarSort === true ? 'changePrecent' : 'none')} selectd={false}>
          {displayKbarSort === true ? 'K棒' : ''}
        </styleds.header.item>
        <styleds.header.item onClick={() => handleColumnSelected('symbol')} selectd={sort === 'symbol'}>
          代號
          <SortArrow sortKey='symbol' sotre={sortStore()}/>
        </styleds.header.item>
        <styleds.header.item onClick={() => handleColumnSelected('close')} selectd={sort === 'close'}>
          成交
          <SortArrow sortKey='close' sotre={sortStore()}/>
        </styleds.header.item>
        <styleds.header.item onClick={() => handleColumnSelected('changePrecent')} selectd={sort === 'changePrecent'}>
          幅度%
          <SortArrow sortKey='changePrecent' sotre={sortStore()}/>
        </styleds.header.item>
        <styleds.header.item onClick={() => handleColumnSelected('volume')} selectd={sort === 'volume'}>
          總量
          <SortArrow sortKey='volume' sotre={sortStore()}/>
        </styleds.header.item>
        <styleds.header.item onClick={() => handleColumnSelected('amount')} selectd={sort === 'amount'}>
          成交值
          <SortArrow sortKey='amount' sotre={sortStore()}/>
        </styleds.header.item>
      </styleds.header.container>);
});
export default {
    body: SymbolBody,
    header: SymbolHeader,
};
const styleds = {
    header: {
        container: styled.div `
      display: grid;
      grid-template-columns: 4% 10% 18% 16% 20% 16% 16%;
      height: 36px;
      width: 100%;
      border-radius: 4px;
      cursor: pointer;
      background-color: #1a1e27;
      user-select: none;
      padding: 6px 2px;
    `,
        item: styled.div `
      ${fill_horizontal_all_center};
      font-size: 12px;
      background-color: ${props => (props.selectd ? '#303843' : 'transparent')};
      border: 0.6px solid ${props => (props.selectd ? '#e29134' : 'transparent')};
      border-radius: 4px;
      white-space: nowrap;
    `,
    },
    body: {
        container: styled.div `
      display: grid;
      grid-template-columns: 4% 10% 18% 18% 16% 16% 18%;
      height: 36px;
      width: 100%;
      border-radius: 4px;
      padding: 2px 4px;
      font-size: 13px;
      cursor: pointer;
      border: 1px solid ${props => (props.selected ? '#c7c7c7' : 'transparent')};
      background-color: #141821;
      &:hover {
        background-color: #1b1e26;
        transition: 0.3s;
      }
      flex-shrink: 0;
    `,
        addButton: styled.div `
      ${fill_horizontal_all_center};
      justify-content: end;
      height: 50%;
      margin-top: 9px;
      border-radius: 4px;
      &:hover {
        transform: scale(1.2);
      }
      &:active {
        transform: scale(1.4);
      }
    `,
        content: styled.div `
      ${fill_horizontal_all_center};
      justify-content: end;
    `,
        addContent: styled.div `
      ${fill_vertical_all_center};
    `,
        symbolNameContent: styled.div `
      ${fill_vertical_all_center};
      align-items: start;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      font-size: 12px;
      &:hover {
        overflow: visible;
      }
      & > * {
        line-height: 16px;
      }
    `,
        quote: styled.div `
      ${fill_horizontal_all_center};
      width: 42px;
      border-radius: 6px;
      color: ${props => (props.quote > 0 ? '#d51f1f' : props.quote < 0 ? '#59c03d' : '#cccc22')};
      font-size: 13px;
      font-weight: 500;
      justify-content: end;
    `,
        price: styled.div `
      background-color: ${props => props.limit === true && props.quote > 0
            ? '#ff0000'
            : props.limit === true && props.quote < 0
                ? '#00aa00'
                : 'transparent'};

      padding: 2px;
      border-radius: 2px;
      color: ${props => (props.limit === true ? '#ffffff' : '')};
    `,
    },
};
